

 import React from 'react';

export const Grid = ({positions,gridSize}) => {
  
  const grid = Array.from({ length: gridSize }, () => Array(gridSize).fill(''));

  // Placer les mots dans la grille
  positions.forEach(({ word, row, col, direction }) => {
    if (direction === 'horizontal') {
      for (let i = 0; i < word.length; i++) {
        grid[row][col + i] = word[i];
      }
    } else if (direction === 'vertical') {
      for (let i = 0; i < word.length; i++) {
        grid[row + i][col] = word[i];
      }
    }
  });

  return (
    <div className="sous_parent_main_croisés_contenair" style={{ display: 'grid', gridTemplateColumns: `repeat(${gridSize}, 1fr)` }}>
      {grid.map((row, rowIndex) =>
        row.map((cell, colIndex) => (
          <input
            key={`${rowIndex}-${colIndex}`}
          value={cell}
          style={{ 
                           backgroundColor: cell === '' ? 'transparent' : 'white',
                           border: cell === '' ? 'grey' : '',
                         }}/>
           
        ))
      )}
    </div>
  );
};

